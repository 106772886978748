import _node2json from "./node2json";
import _xmlstr2xmlnode from "./xmlstr2xmlnode";
import _util from "./util";
import _validator from "./validator";
import _nimndata from "./nimndata";
import _node2json_str from "./node2json_str";
import _json2xml from "./json2xml";
var exports = {};
const nodeToJson = _node2json;
const xmlToNodeobj = _xmlstr2xmlnode;
const x2xmlnode = _xmlstr2xmlnode;
const buildOptions = _util.buildOptions;
const validator = _validator;

exports.parse = function (xmlData, givenOptions = {}, validationOption) {
  if (validationOption) {
    if (validationOption === true) validationOption = {};
    const result = validator.validate(xmlData, validationOption);

    if (result !== true) {
      throw Error(result.err.msg);
    }
  }

  if (givenOptions.parseTrueNumberOnly && givenOptions.parseNodeValue !== false && !givenOptions.numParseOptions) {
    givenOptions.numParseOptions = {
      leadingZeros: false
    };
  }

  let options = buildOptions(givenOptions, x2xmlnode.defaultOptions, x2xmlnode.props);
  const traversableObj = xmlToNodeobj.getTraversalObj(xmlData, options); //print(traversableObj, "  ");

  return nodeToJson.convertToJson(traversableObj, options);
};

exports.convertTonimn = _nimndata.convert2nimn;
exports.getTraversalObj = xmlToNodeobj.getTraversalObj;
exports.convertToJson = nodeToJson.convertToJson;
exports.convertToJsonString = _node2json_str.convertToJsonString;
exports.validate = validator.validate;
exports.j2xParser = _json2xml;

exports.parseToNimn = function (xmlData, schema, options) {
  return exports.convertTonimn(exports.getTraversalObj(xmlData, options), schema, options);
};

function print(xmlNode, indentation) {
  if (xmlNode) {
    console.log(indentation + "{");
    console.log(indentation + "  \"tagName\": \"" + xmlNode.tagname + "\", ");

    if (xmlNode.parent) {
      console.log(indentation + "  \"parent\": \"" + xmlNode.parent.tagname + "\", ");
    }

    console.log(indentation + "  \"val\": \"" + xmlNode.val + "\", ");
    console.log(indentation + "  \"attrs\": " + JSON.stringify(xmlNode.attrsMap, null, 4) + ", ");

    if (xmlNode.child) {
      console.log(indentation + "\"child\": {");
      const indentation2 = indentation + indentation;
      Object.keys(xmlNode.child).forEach(function (key) {
        const node = xmlNode.child[key];

        if (Array.isArray(node)) {
          console.log(indentation + "\"" + key + "\" :[");
          node.forEach(function (item, index) {
            //console.log(indentation + " \""+index+"\" : [")
            print(item, indentation2);
          });
          console.log(indentation + "],");
        } else {
          console.log(indentation + " \"" + key + "\" : {");
          print(node, indentation2);
          console.log(indentation + "},");
        }
      });
      console.log(indentation + "},");
    }

    console.log(indentation + "},");
  }
}

export default exports;
export const parse = exports.parse,
      convertTonimn = exports.convertTonimn,
      getTraversalObj = exports.getTraversalObj,
      convertToJson = exports.convertToJson,
      convertToJsonString = exports.convertToJsonString,
      validate = exports.validate,
      j2xParser = exports.j2xParser,
      parseToNimn = exports.parseToNimn;